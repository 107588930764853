@import "utils/vars/colors.scss";

.badge {
  width: 100%;
  height: 100%;
  text-align: center;
  border-radius: 30px;
  background: $yellow-gradient;
  font-size: 15px;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

.badgeWithIcon {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  padding-left: 30px;
  border-radius: 30px;
  background: linear-gradient(131.35deg, #cb97ff 7.53%, #583dff 94.2%);
  box-sizing: border-box;
  font-size: 15px;
  font-weight: 400;
  color: $white;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    position: absolute;
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
    width: 24px;
    height: 24px;
  }
}
