.sliderCard {
  background: linear-gradient(
    111.68deg,
    rgba(255, 255, 255, 0.2) 7.59%,
    rgba(255, 255, 255, 0.5) 102.04%
  );
  border-radius: 50px;
  padding-top: 30px;
  padding-bottom: 30px;
  overflow: hidden;
  width: 310px;

  &__openedCardTitleWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__openedCardTitle {
    text-align: center;
    font-weight: 900;
    font-size: 25px;
    margin-bottom: 15px;
  }

  &__openedCardImage {
    width: 130px;
    height: 130px;
    object-fit: cover;
    margin-bottom: 20px;
  }

  &__elementWrapper {
    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__elementLink {
    text-decoration: none;
  }

  .button {
    font-size: 16px;
    width: 80%;
    font-weight: 700;
    padding: 14px 0;
    margin-left: 50%;
    margin-top: 15px;
    text-align: center;
    transform: translateX(-50%);
    transition: 0.3s;
    &::before {
      display: none;
    }
  }
}

.sliderCardItem {
  padding-right: 25px;
  display: flex;

  &__iconWrapper {
    background: linear-gradient(180deg, #ffd107 0%, #ffe15f 100%);
    border-radius: 0px 30px 30px 0px;
    max-width: 50px;
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__content {
    width: 100%;
    margin-left: 15px;
  }

  &__contentTitle {
    display: flex;
    justify-content: space-between;

    img {
      height: 22px;
      width: 23px;
    }

    h5 {
      background: linear-gradient(131.35deg, #cb97ff 7.53%, #583dff 94.2%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      font-weight: 700;
      font-size: 18px;
    }

    p {
      margin-top: 5px;
      font-weight: 400;
      font-size: 15px;
      line-height: 18px;
      color: #595566;
    }
  }
}

@media (max-width: 575.98px) {
  .sliderCard {
    .button {
      margin-bottom: 12px;
    }
  }
}
