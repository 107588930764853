@import "utils/vars/colors.scss";

.communities {
  display: flex;
  align-items: center;
  margin-bottom: 300px;

  &__left {
    max-width: 420px;
    width: 100%;
    margin-right: 130px;
  }

  &__right {
    max-width: 670px;
    width: 100%;
  }

  .title {
    margin-bottom: 50px;
  }

  .subtitle {
    margin-bottom: 20px;
  }

  .communitiesCard {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.communitiesCard {
  display: flex;
  align-items: center;
  background: linear-gradient(
    111.68deg,
    rgba(255, 255, 255, 0.2) 7.59%,
    rgba(255, 255, 255, 0.5) 102.04%
  );
  box-shadow: 0px 15px 60px rgba(70, 61, 128, 0.2);

  border-radius: 50px;

  &__imgWrapper {
    background: linear-gradient(180deg, #ffd107 0%, #ffe15f 100%);
    border-radius: 50%;
    max-width: 60px;
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
  }

  &__text {
    color: $base-text-color;
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
  }
}

@media (max-width: 991.98px) {
  .communities {
    flex-direction: column-reverse;
    margin-bottom: 100px;

    &__left {
      margin-right: 0;
    }

    &__right {
      margin-bottom: 40px;

      .title {
        margin-bottom: 15px;
      }
    }
  }
}
