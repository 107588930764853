.commonProjects {
  margin-top: 130px;

  .rectangle {
    display: none;
  }

  &__cardsWrapper {
    margin-top: 50px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    row-gap: 40px;
    margin-bottom: 100px;
    column-gap: 20px;
  }
}

@media (max-width: 991.98px) {
  .commonProjects {
    &__cardsWrapper {
      grid-template-columns: repeat(2, 1fr);
      justify-items: center;
    }
  }
}

@media (max-width: 575.98px) {
  .commonProjects {
    margin-top: 40px;

    .rectangle {
      display: block;
      position: absolute;
      top: 100px;
      right: 0;
    }

    &__cardsWrapper {
      grid-template-columns: repeat(1, 1fr);
      justify-items: center;
    }
  }
}
