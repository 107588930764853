


strong {
  font-weight: 700;
}

ol, ul {
  list-style: auto;
}

ol {
  padding-left: 40px;
  padding-top: 30px;
}

@import "pages/Impressum/styles.scss";
@import "utils/vars/colors.scss";