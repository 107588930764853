@import "utils/vars/colors.scss";

.card {
  max-width: 420px;
  width: 100%;
  background: linear-gradient(
    111.68deg,
    rgba(255, 255, 255, 1) 7.59%,
    rgba(255, 255, 255, 1) 102.04%
  );
  box-shadow: 0px 15px 60px rgba(70, 61, 128, 0.2);

  border-radius: 50px;

  &__iconWrapper {
    background: linear-gradient(180deg, #ffd107 0%, #ffe15f 100%);
    border-radius: 50px 0px;
    max-width: 310px;
    width: 100%;
    height: 100px;
    position: relative;
  }

  &__img {
    position: absolute;
    left: 7.14%;
    bottom: 0;
  }

  &__content {
    padding: 20px 30px 44px 30px;
  }

  .subtitle {
    margin-bottom: 10px;
  }
}

.cardMeetup {
  width: 100%;
  background: $white;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  &:hover {
    box-shadow: 0px 15px 60px rgba(70, 61, 128, 0.2);
    cursor: pointer;
  }

  &__previewImg {
    height: 150px;
    object-fit: cover;
    border-radius: 30px 30px 0 0;
  }

  .title-gradient {
    margin-bottom: 10px;
  }

  .subtitle {
    margin-bottom: 10px;
  }

  &__content {
    padding: 20px 30px;
  }

  .text {
    color: $grey;
  }

  &__buttonsWrapper {
    margin-top: 15px;
    display: flex;
    align-items: center;
  }

  &__events {
    max-width: 170px;
    width: 100%;
    height: 30px;
  }

  &__info {
    max-width: 150px;
    width: 100%;
    height: 30px;
  }
  &__footerMobile {
    display: none;
  }
  &__footer {
    margin-top: 50px;
    display: flex;
  }

  &__footerCard {
    background: linear-gradient(
      131.35deg,
      rgba(203, 151, 255, 0.3) 7.53%,
      rgba(88, 61, 255, 0.3) 94.2%
    );
    height: 110px;
    position: relative;
    max-width: 130px;
    width: 100%;
    border-radius: 30px 30px 0 0;
    margin-right: 15px;
    padding: 32px 12px 10px 12px;
    box-sizing: border-box;

    &:last-child {
      margin-right: 0;
    }
  }

  &__footerCardAvatar {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -25px;
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 50%;
  }

  &__footerCardName {
    font-size: 15px;
    font-weight: 700;
    margin-bottom: 3px;
    text-align: center;
  }

  &__footerCardDescr {
    font-size: 13px;
    font-weight: 400;
    color: $grey;
    line-height: 16px;
    text-align: center;
  }
}

.cardProject {
  max-width: 420px;
  width: 100%;
  background: $white;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  &__previewImg {
    height: 150px;
    object-fit: cover;
    width: 100%;
    border-radius: 30px 30px 0px 0px;
  }

  &__content {
    padding: 20px 30px 28px 30px;
  }

  .subtitle {
    margin-bottom: 10px;
    a {
      text-decoration: none;
      color: $base-text-color;
      &:hover {
        cursor: pointer;
        background: linear-gradient(131.35deg, #cb97ff 7.53%, #583dff 94.2%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
      }
    }
  }

  .text {
    color: $grey;
  }

  /* &__footer {
    background: linear-gradient(
      131.35deg,
      rgba(203, 151, 255, 0.3) 7.53%,
      rgba(88, 61, 255, 0.3) 94.2%
    );
    max-width: 305px;
    width: 100%;
    border-radius: 0px 30px 0px 30px;
    padding: 20px 30px;
    box-sizing: border-box;
    display: flex;
    align-items: center;

    img {
      width: 50px;
      height: 50px;
      object-fit: cover;
      border-radius: 50%;
    }
  } */

  &__footer {
    margin-top: 50px;
    display: flex;
  }

  &__footerItem {
    background: linear-gradient(
      131.35deg,
      rgba(203, 151, 255, 0.3) 7.53%,
      rgba(88, 61, 255, 0.3) 94.2%
    );
    height: 65px;
    position: relative;
    max-width: 130px;
    width: 100%;
    border-radius: 30px 30px 0 0;
    margin-right: 15px;
    padding: 32px 12px 10px 12px;
    box-sizing: border-box;
    &:last-child {
      margin-right: 0;
    }
    img {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: -25px;
      width: 50px;
      height: 50px;
      object-fit: cover;
      border-radius: 50%;
    }
  }

  &__userInfo {
    display: flex;
    flex-direction: column;
    text-align: center;

    h4 {
      font-size: 15px;
      font-weight: 700;
      margin-bottom: 5px;
      a {
        text-decoration: none;
        cursor: pointer;
        color: $base-text-color;
        &:hover {
          background: linear-gradient(131.35deg, #cb97ff 7.53%, #583dff 94.2%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
        }
      }
    }

    p {
      font-size: 15px;
      font-weight: 400;
      color: $grey;
    }
  }
}
.cardContacts {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 310px;
  width: 100%;
  background: $white;
  border-radius: 30px;
  position: relative;
  margin-top: 20px;

  &__avatarImg {
    position: absolute;
    top: -40px;
    left: 50%;
    transform: translateX(-50%);
    width: 90px;
    height: 90px;
    object-fit: cover;
    border-radius: 50%;
  }

  &__text {
    margin-top: 5px;
    margin-bottom: 40px;
  }

  &__socialIconsWrapper {
    position: absolute;
    bottom: -10px;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    left: 50%;
    transform: translateX(-50%);
  }

  &__socialIcon {
    width: 30px;
    height: 30px;
    object-fit: cover;
    margin-right: 15px;
    cursor: pointer;
    &:last-child {
      margin-right: 0;
    }
  }
  .subtitle {
    margin-top: 65px;
  }
}

.cardVacancies {
  max-width: 420px;
  width: 100%;
  background: $white;
  border-radius: 30px;
  box-sizing: border-box;
  height: fit-content;

  &:hover {
    box-shadow: 0px 15px 60px rgba(70, 61, 128, 0.2);
  }

  &__content {
    margin-bottom: 20px;
    padding: 30px;
  }

  &__date {
    font-size: 15px;
    font-weight: 400;
    line-height: 18px;
    background: linear-gradient(131.35deg, #cb97ff 7.53%, #583dff 94.2%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    margin-bottom: 20px;
  }
  &__title {
    font-size: 25px;
    font-weight: 700;
    line-height: 30px;
    color: $base-text-color;
    margin-bottom: 10px;
  }
  &__description {
    font-size: 15px;
    font-weight: 400;
    line-height: 18px;
    color: $grey;
    margin-bottom: 30px;
  }
  &__subtitle {
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
    color: $base-text-color;
  }
  &__wrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  &__button {
    margin: 0px !important;
    max-width: 310px;
    box-sizing: border-box;
    width: 100%;
    background: linear-gradient(131.35deg, #cb97ff 7.53%, #583dff 94.2%);
    border-radius: 0px 30px 30px 30px;
    border: none;
    font-weight: 700;
    font-size: 18px;
    text-align: center;
    color: $white;
    padding: 19px;

    &:hover {
      cursor: pointer;
      color: $base-text-color;
      background: linear-gradient(180deg, #ffd107 0%, #ffe15f 100%);
    }
  }
}

@media (max-width: 575.98px) {
  .card {
    &__iconWrapper {
      max-width: 155px;
      height: 50px;
    }

    &__img {
      width: 100px;
    }
  }

  .cardMeetup {
    width: 100%;
    box-sizing: border-box;

    &__footer {
      display: none;
    }

    &__footerMobile {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 0 20px 20px 20px;
    }

    &__footerCardMobile {
      display: flex;
      margin-top: 10px;
    }

    &__footerCardName {
      text-align: left;
    }

    &__footerCardAvatarMobile {
      width: 40px;
      height: 40px;
      object-fit: cover;
      border-radius: 50%;
      margin-right: 10px;
    }
    &__footerCardNameWrapper {
      display: flex;
      flex-direction: column;
    }
  }
}
