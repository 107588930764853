.howToJoin {
  margin-bottom: 200px;
  .title {
    margin-bottom: 118px;
  }

  &__cardsWrapper {
    position: relative;
    height: 876px;
  }

  &__topCardWrapper {
    position: relative;
  }

  &__topLine {
    display: block;
    position: absolute;
    left: 388px;
    top: 30px;
    z-index: -1;
  }

  &__topLineMobile {
    display: none;
  }

  &__middleCardWrapper {
    position: absolute;
    right: 15px;
    top: 180px;
  }

  &__bottomCardWrapper {
    position: absolute;
    bottom: 0;
    left: 0;
  }

  &__bottomLine {
    display: block;
    position: absolute;
    left: 387px;
    bottom: 105px;
    z-index: -1;
  }

  &__bottomLineMobile {
    display: none;
  }
}

@media (max-width: 1199.98px) {
  .howToJoin {
    &__bottomLine,
    &__topLine {
      left: 160px;
    }
  }
}

@media (max-width: 991.98px) {
  .howToJoin {
    margin-bottom: 400px;

    &__topLine,
    &__bottomLine {
      display: none;
    }

    &__bottomLineMobile {
      display: block;
      position: absolute;
      top: -110px;
      right: 300px;
      z-index: -1;
    }

    &__topCardWrapper {
      display: flex;
      justify-content: center;
    }

    &__middleCardWrapper {
      position: unset;
      margin-bottom: 100px;
      margin-top: 100px;
      display: flex;
      justify-content: center;
    }

    &__bottomCardWrapper {
      position: relative;
      display: flex;
      justify-content: center;
    }

    &__topLineMobile {
      display: block;
      position: absolute;
      top: 0;
      right: 300px;
      z-index: -1;
    }
  }
}

@media (max-width: 767.98px) {
  .howToJoin {
    margin-bottom: 400px;

    &__topLine,
    &__bottomLine {
      display: none;
    }

    &__bottomLineMobile {
      display: block;
      position: absolute;
      top: -110px;
      right: 180px;
      z-index: -1;
    }

    &__topCardWrapper {
      display: flex;
      justify-content: center;
    }

    &__middleCardWrapper {
      position: unset;
      margin-bottom: 100px;
      margin-top: 100px;
      display: flex;
      justify-content: center;
    }

    &__bottomCardWrapper {
      position: relative;
      display: flex;
      justify-content: center;
    }

    &__topLineMobile {
      display: block;
      position: absolute;
      top: 0;
      right: 180px;
      z-index: -1;
    }
  }
}

@media (max-width: 575.98px) {
  .howToJoin {
    margin-bottom: 100px;
    &__topLine,
    &__bottomLine {
      display: none;
    }

    &__bottomLineMobile {
      display: block;
      position: absolute;
      top: -110px;
      right: 90px;
      z-index: -1;
    }

    &__topCardWrapper {
      display: flex;
      justify-content: center;
    }

    &__middleCardWrapper {
      position: unset;
      margin-bottom: 100px;
      margin-top: 100px;
      display: flex;
      justify-content: center;
    }

    &__bottomCardWrapper {
      position: relative;
      display: flex;
      justify-content: center;
    }

    &__topLineMobile {
      display: block;
      position: absolute;
      top: 0;
      right: 90px;
      z-index: -1;
    }
  }
}
