@import "utils/vars/colors.scss";

.privacy-policy {
  margin-bottom: 220px;
  margin-top: 130px;

  &__rectangle {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: -1;
  }

  &__list {
    list-style: disc;
    padding-left: 20px;
    li {
      padding-bottom: 10px;
    }
    &_nested {
      padding-left: 40px;
    }
  }

  &__rectangle-mobile {
    display: none;
  }

  &__contentWrapper {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
  }

  &__adressText {
    max-width: 323px;
    width: 100%;
    font-size: 22px;
    font-weight: 400;
    line-height: 33px;
    margin-bottom: 50px;
  }

  &__link {
    font-size: 22px;
    font-weight: 400;
    line-height: 33px;
    padding-bottom: 10px;
    a {
      text-decoration: none;
    }
  }

  &__name {
    margin-bottom: 30px;
  }

  &__content {
    margin-top: 60px;
    width: 100%;
  }
  &__textBlock {
    margin-bottom: 40px;
    h3 {
      margin-bottom: 20px;
    }
  }
}

@media (max-width: 991.98px) {
  .privacy-policy {
    &__rectangle {
      display: none;
    }

    &__rectangle-mobile {
      position: absolute;
      right: 0;
      bottom: 0;
      display: block;
      z-index: -1;
    }
  }
}

@media (max-width: 575.98px) {
  .privacy-policy {
    margin-top: 40px;
    margin-bottom: 80px;
    .subtitle {
      padding-bottom: 8px;
    }

    &__textBlock {
      margin-bottom: 25px;
    }
  }
}
