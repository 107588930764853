.vacanciesHeader {
  display: flex;
  margin-bottom: 50px;
  &__searchInputWrapper {
    max-width: 640px;
    width: 100%;
    margin-right: 20px;
  }
  &__inputWrapper {
    max-width: 640px;
    width: 100%;
    margin-right: 20px;
  }
}

@media (max-width: 767.98px) {
  .vacanciesHeader {
    flex-direction: column;
    align-items: center;

    &__searchInputWrapper, &__inputWrapper {
      margin-bottom: 15px;
    }

    .button {
      margin-right: 20px;
    }

  }
}
