@import "utils/vars/colors.scss";

/* RESET STYLES

GLOBAL STYLES */

body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  background: linear-gradient(103.48deg, #fafafa 0%, #f1eef8 100%);
  position: relative;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.container {
  max-width: 1300px;
  width: auto;
  margin: auto;
  padding: 0 15px;
}

.title {
  font-size: 55px;
  font-weight: 900;
  color: $base-text-color;
  line-height: 67px;
}

.title-gradient,
.link {
  background: linear-gradient(131.35deg, #cb97ff 7.53%, #583dff 94.2%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.link {
  text-decoration: none;
}

.title p {
  font-size: 40px;
}

.subtitle {
  font-size: 25px;
  font-weight: 700;
  line-height: 30px;
  color: $base-text-color;
}

.text {
  font-size: 18px;
  font-weight: 400;
  line-height: 150%;
  color: $base-text-color;
}

.fw-bold {
  font-weight: 700;
}

.pt-10 {
  padding-top: 10px;
}
.pt-20 {
  padding-top: 20px;
}
.pt-30 {
  padding-top: 30px;
}
.pt-40 {
  padding-top: 40px;
}
.pb-10 {
  padding-bottom: 10px;
}
.pb-20 {
  padding-bottom: 20px;
}
.pb-30 {
  padding-bottom: 30px;
}
.pb-40 {
  padding-bottom: 40px;
}

@media (max-width: 575.98px) {
  .title {
    font-size: 30px;
    line-height: 36px;
  }

  .subtitle {
    font-size: 18px;
    line-height: 22px;
  }

  .text {
    font-size: 15px;
    line-height: 18px;
  }

  .container {
    max-width: none;
    width: auto;
  }
}
/* TODO: remove later
 .leaflet-container {
   width: 500px;
   height: 500px;
  background: transparent;
 } */
