.boardBody {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  row-gap: 40px;
  margin-bottom: 100px;
  column-gap: 20px;

  &__cardWrapper {
    display: flex;
    flex-direction: column;
    height: fit-content;
    width: 100%;
    align-items: center;
  }
}

@media (max-width: 991.98px) {
  .boardBody {
    grid-template-columns: repeat(2, 1fr);
    justify-items: center;
  }
}

@media (max-width: 767.98px) {
  .boardBody {
    grid-template-columns: repeat(1, 1fr);
    justify-items: center;
    row-gap: 30px;
  }
}
