@import "utils/vars/colors.scss";

.contacts {
  margin-top: 130px;

  .rectangle {
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
  }

  .rectangle-mobile {
    display: none;
  }

  &__cardsWrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    row-gap: 40px;
    margin-bottom: 200px;
    column-gap: 20px;
    margin-top: 70px;
  }
}

@media (max-width: 991.98px) {
  .contacts {
    &__cardsWrapper {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}

@media (max-width: 767.98px) {
  .contacts {
    .rectangle {
      display: none;
    }

    .rectangle-mobile {
      position: absolute;
      z-index: -1;
      display: block;
      bottom: 0;
      right: 0;
    }

    &__cardsWrapper {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

@media (max-width: 575.98px) {
  .contacts {
    margin-top: 40px;

    .rectangle {
      display: none;
    }

    .rectangle-mobile {
      position: absolute;
      z-index: -1;
      display: block;
      bottom: 0;
      right: 0;
    }

    &__cardsWrapper {
      grid-template-columns: repeat(1, 1fr);
      justify-items: center;
      row-gap: 80px;
    }
  }
}
