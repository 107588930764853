.vacancies {
  &__cardsWrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    row-gap: 30px;
    margin-bottom: 200px;
    column-gap: 20px;
  }
}

@media (max-width: 991.98px) {
  .vacancies {
    &__cardsWrapper {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

@media (max-width: 767.98px) {
  .vacancies {
    &__cardsWrapper {
      grid-template-columns: repeat(1, 1fr);
      row-gap: 20px;
      margin-bottom: 100px;
    }

    &__cardWrapper {
      display: flex;
      justify-content: center;
    }
  }
}
