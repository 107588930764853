.boardHeader {
  display: flex;
  margin-bottom: 50px;

  &__searchWrapper {
    display: flex;
    width: 100%;
  }

  &__searchInputMobbile {
    display: none;
  }

  &__searchInputWrapper {
    max-width: 640px;
    width: 100%;
  }

  &__inputWrapper {
    max-width: 310px;
    width: 100%;
    margin-left: 20px;
  }
}

@media (max-width: 767.98px) {
  .boardHeader {
    display: flex;

    &__searchWrapper {
      display: flex;
      flex-direction: column;
    }

    &__searchInputWrapper {
      max-width: none;
    }

    &__inputWrapper {
      margin-left: 0;
      max-width: none;
      margin-top: 20px;
    }
  }
}

@media (max-width: 575.98px) {
  .boardHeader {
    display: flex;

    &__searchWrapper {
      display: none;
    }

    &__searchInputMobbile {
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    &__wrapper {
      max-width: 550px;
    }

    &__dropInput {
      width: 100%;
    }

    &__inputWrapper {
      margin-left: 0;
      max-width: none;
      margin-top: 20px;
    }
  }
}
