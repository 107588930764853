.roundButton {
  border-radius: 50%;
  max-width: 50px;
  width: 50px;
  height: 50px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  background: linear-gradient(131.35deg, #cb97ff 7.53%, #583dff 94.2%);
  border: none;
  cursor: pointer;

  &:hover {
    background: linear-gradient(131.35deg, #f097ff 7.53%, #9b24e4 94.2%);
  }
}
