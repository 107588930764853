@import "utils/vars/colors.scss";

.popover {
  position: absolute;
  bottom: 50%;
  /* right: -80px; */
  right: 0px;
  width: 230px;
  height: 80px;
  background: linear-gradient(180deg, #ffd107 0%, #ffe15f 100%);
  border-radius: 40px 40px 40px 40px;
  /* border-radius: 40px 40px 40px 0px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__header {
    font-weight: 900 !important;
    font-size: 25px;
    color: $base-text-color;
    display: flex;
    align-items: center;
  }

  &__description {
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    text-align: center;
    color: $base-text-color;
  }

  &__icon {
    width: 24px;
    height: 24px;
  }
}

@media (max-width: 991.98px) {
  .popover {
    right: -100px;
  }
}
