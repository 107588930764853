.footer {
  padding-bottom: 150px;
  .container {
    display: flex;
    justify-content: space-between;
  }

  .text {
    margin-bottom: 15px;
    display: block;
    text-decoration: none;

    &:hover {
      background: linear-gradient(131.35deg, #cb97ff 7.53%, #583dff 94.2%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__rightContent {
    display: flex;
    flex-direction: column;
  }

  &__iconsWrapper {
    display: flex;
    margin-bottom: 42px;
  }
  &__icon {
    width: 31px;
    margin-right: 20px;
    img {
      width: 100%;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

@media (max-width: 575.98px) {
  .footer {
    padding-bottom: 40px;
    .container {
      flex-direction: column;
    }

    &__nav {
      &:nth-child(1),
      &:nth-child(2) {
        margin-bottom: 15px;
      }

      &:nth-child(3) {
        margin-bottom: 40px;
      }
    }
    &__iconsWrapper {
      margin-bottom: 30px;
    }
  }
}
