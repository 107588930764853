@import "utils/vars/colors.scss";

.vacancies {
  margin-top: 130px;
  .title {
    margin-right: 30px;
  }

  .rectangle-mobile {
    display: none;
  }

  &__titleWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 50px;
  }

  &__text {
    color: $grey;
    font-size: 25px;
    line-height: 30px;
    font-weight: 400;
  }
}

@media (max-width: 767.98px) {
  .vacancies {
    margin-top: 40px;

    .rectangle-mobile {
      display: block;
      position: absolute;
      right: 0;
      z-index: -1;
    }

    &__titleWrapper {
      flex-direction: column;
      align-items: flex-start;
    }
  }
}
